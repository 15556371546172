import React, { useState } from "react";
import Layout from "../../components/Layout";
import ExploreCollectionBanner from "../../components/molecules/banners/ExploreCollectionBanner";
import NftGridLayout from "../../components/molecules/NftGridLayout";
import NftCardDescription from "../../components/molecules/nftCards/NftCardDescription";
import CollectionFilter from "../../components/molecules/CollectionFilter";
import { useEffect } from "react";
import Button from "../../components/atoms/Button";

import { Helmet } from "react-helmet";

import {
  getArtistsNamesFromArtefyCollection,
  getArtistNamesFromArtefyCollections,
} from "../../utils/utils";

const pageName = "NFT Marketplace Collections";

const sortOptions = ["Latest", "Oldest", "A-Z", "Z-A"];
const availabilityFilterOptions = ["All", "Sale", "Not for sale"];

const IndexPage = ({ pageContext }) => {
  const initialCollections = 8;
  const nextCollections = 4;

  const [collectionList, setCollectionList] = useState([]);
  const [finalCollectionList, setFinalCollectionList] =
    useState(collectionList);
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);

  const [showMoreButton, setShowMoreButton] = useState(false);
  const [sortFilter, setSortFilter] = useState("");
  const [artistFilter, setArtistFilter] = useState("All");
  const [collectionFilter, setCollectionFilter] = useState("All");
  const [collectionFilterOptions, setCollectionFilterOptions] = useState();
  const [availabilityFilter, setAvailabilityFilter] = useState("All");
  const { collections } = pageContext;

  const handleOnSearch = (e) => {
    const input = e.target.value.toLowerCase();
    const filteredList = collections.filter((collection) => {
      return collection.name.toLowerCase().includes(input);
    });
    if (filteredList.length === 0) {
      setSearchResultEmpty(true);
    } else {
      setSearchResultEmpty(false);
    }
    setFinalCollectionList(filteredList);
  };

  const handleCollectionFilter = (option) => {
    setCollectionFilter(option);
    if (option === "All") {
      setFinalCollectionList(collections);
      return;
    }
    setFinalCollectionList(collections.filter((item) => item?.name === option));
  };

  const handleAvailabilityFilter = (option) => {
    const currentTime = new Date();
    setAvailabilityFilter(option);
    const notSale = [];
    const sale = [];
    collections.forEach((collection) => {
      let liveFlag = false;

      for (let item of collection?.aspen_collections) {
        const start = item?.aspen_tokens[0]?.drop?.drop_start;
        const end = item?.aspen_tokens[0]?.drop?.drop_end;
        if (start && end) {
          if (new Date(end) > currentTime && new Date(start) < currentTime) {
            liveFlag = true;
          }
        }
        if (liveFlag) break;
      }
      if (liveFlag) {
        sale.push(collection);
      } else {
        notSale.push(collection);
      }
    });
    if (option === "Sale") {
      setFinalCollectionList(sale);
    }
    if (option === "Not for sale") {
      setFinalCollectionList(notSale);
    }
    if (option === "All") {
      setFinalCollectionList(collections);
    }
  };

  const scrollWondow = () => {
    window.scrollTo({ top: 500, behavior: "smooth" });
  };

  useEffect(() => {
    if (collectionList.length) {
      const filterOptions = ["All"];
      collectionList.forEach((item) => filterOptions.push(item?.name));
      setCollectionFilterOptions(filterOptions);
    }
  }, [collectionList]);

  useEffect(() => {
    if (collections) {
      let updatedList = [...collections];
      if (sortFilter) {
        switch (sortFilter) {
          case sortOptions[0]:
            updatedList = updatedList.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            break;
          case sortOptions[1]:
            updatedList = updatedList.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            break;
          case sortOptions[2]:
            updatedList = updatedList.sort((a, b) => {
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (x < y) return -1;
              if (x > y) return 1;
              return 0;
            });
            break;
          case sortOptions[3]:
            updatedList = updatedList.sort((a, b) => {
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (x < y) return 1;
              if (x > y) return -1;
              return 0;
            });
            break;
          default:
            break;
        }
        setCollectionFilter("All");
      }
      if (artistFilter) {
        if (artistFilter !== "All") {
          updatedList = updatedList.filter((item) => {
            const artist = getArtistsNamesFromArtefyCollection(item);
            return artist.includes(artistFilter);
          });
        }
        setCollectionFilter("All");
      }
      setCollectionList(updatedList);
    }
  }, [sortFilter, artistFilter, collections]);

  useEffect(() => {
    if (collectionList) {
      if (collectionList.length <= initialCollections) {
        setFinalCollectionList(collectionList);
        setShowMoreButton(false);
      } else {
        setFinalCollectionList(collectionList.slice(0, initialCollections));
        setShowMoreButton(true);
      }
    }
  }, [collectionList]);

  const handleLoadMore = () => {
    if (collectionList.length > finalCollectionList.length) {
      if (
        collectionList.length - finalCollectionList.length >
        nextCollections
      ) {
        setFinalCollectionList(
          collectionList.slice(0, finalCollectionList.length + nextCollections)
        );
        setShowMoreButton(true);
      } else {
        setFinalCollectionList(collectionList);
        setShowMoreButton(false);
      }
    } else {
      setFinalCollectionList(collectionList);
      setShowMoreButton(false);
    }
  };
  //TODO: Ideally,...<CollectionFilter should take full artist as input and
  // on selection it should return selected one. better to use ...getArtistsFromArtefyCollections(collectionList)
  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${pageName}`}</title>
      </Helmet>
      <ExploreCollectionBanner
        onSearchHandler={handleOnSearch}
        scrollWondow={scrollWondow}
      />
      {collectionList.length ? (
        <>
          <div className="grid sm:grid-rows-2 sm:grid-cols-2 gap-4 py-10 lg:grid-cols-4 lg:grid-rows-1 max-w-[1200px] mx-auto">
            <CollectionFilter
              name="Collection"
              selectedOption={collectionFilter}
              options={collectionFilterOptions}
              onSelectOption={handleCollectionFilter}
            />
            <CollectionFilter
              name="Creator/Band"
              selectedOption={artistFilter}
              options={[
                "All",
                ...getArtistNamesFromArtefyCollections(collections),
              ]}
              onSelectOption={setArtistFilter}
            />
            <CollectionFilter
              name="Availability"
              selectedOption={availabilityFilter}
              options={availabilityFilterOptions}
              onSelectOption={handleAvailabilityFilter}
            />
            <CollectionFilter
              name="Sort"
              selectedOption={sortFilter}
              options={sortOptions}
              onSelectOption={setSortFilter}
            />
          </div>
          {!searchResultEmpty && (
            <NftGridLayout contentLength={finalCollectionList.length}>
              {finalCollectionList?.map((item) => {
                // TODO: What this code is doing?? not sure.
                let collectionArtist =
                  getArtistsNamesFromArtefyCollection(item);
                return (
                  <NftCardDescription
                    key={item.id}
                    nftCardImg={item.featured_image?.url}
                    name={item.name}
                    description={item.description}
                    link={`/marketplace/nfts/collections/${item.slug}`}
                    artists={collectionArtist}
                  />
                );
              })}
            </NftGridLayout>
          )}
          {searchResultEmpty && (
            <h1 className="text-primary text-4xl text-center py-40">
              no search result
            </h1>
          )}
        </>
      ) : (
        <h1 className="text-primary text-4xl text-center py-40">
          no collections available
        </h1>
      )}
      {showMoreButton && (
        <div className="flex justify-center items-center mb-10">
          <Button onClick={handleLoadMore} type="secondary">
            Load More
          </Button>
        </div>
      )}
    </Layout>
  );
};

export default IndexPage;

import React, { useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const CollectionFilter = ({
  name = "",
  selectedOption = "",
  options = [],
  onSelectOption = () => null,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleOptionChange = (option) => {
    onSelectOption(option);
  };
  return (
    <div className="relative mx-auto w-full">
      {showDropdown && (
        <div
          onClick={setShowDropdown.bind(this, false)}
          className="fixed top-0 right-0 left-0 bottom-0"
          role="button"
          onKeyDown={setShowDropdown.bind(this, false)}
          tabIndex={0}
          aria-label="Close"
        ></div>
      )}
      <button onClick={toggleDropdown} className="mx-auto block">
        <div className="bg-black flex h-12 w-60 items-center pl-4">
          <div className="flex-grow text-left">
            <span className="text-gray">{name}: </span>
            <span className="font-bold">{selectedOption}</span>
          </div>
          <div className="w-12 h-12 flex justify-center items-center bg-primary">
            <ArrowDownwardIcon />
          </div>
        </div>
      </button>
      <ul
        style={showDropdown ? { display: "block" } : { display: "none" }}
        className="hidden absolute top-[100%] w-full min-w-[8rem] left-0 bg-mutedGray -mt-0.5 z-50"
      >
        {options.map((option) => {
          return (
            <li
              className={`${
                selectedOption === option
                  ? "text-whiteVariant bg-redVariant"
                  : ""
              }`}
              key={option}
            >
              <button
                className="px-6 py-3 w-full"
                onClick={handleOptionChange.bind(this, option)}
              >
                {option}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CollectionFilter;
